const REVIEW_STATUS_IDS = {
  REVIEW_STATUS_MODERATING: 0, // На модерации
  REVIEW_STATUS_APPROVED: 1, // Одобренный
  REVIEW_STATUS_REJECTED: 2, // Отклоненный
}

const REVIEW_STATUS_LIST = [
  { id: REVIEW_STATUS_IDS.REVIEW_STATUS_MODERATING, value: 'На модерации' },
  { id: REVIEW_STATUS_IDS.REVIEW_STATUS_APPROVED, value: 'Одобренный' },
  { id: REVIEW_STATUS_IDS.REVIEW_STATUS_REJECTED, value: 'Отклоненный' },
]

export default {

  // Статус отзыва
  REVIEW_STATUS_IDS,
  REVIEW_STATUS_LIST,
}
