<template>
  <b-form @submit.prevent>
    <b-row v-if="clinicReviewData.id && clinicReviewData.clinic">
      <b-col cols="12">
        <router-link :to="{name: 'clinics-update', params: {id: clinicReviewData.clinic.id }}">
          {{ clinicReviewData.clinic ? clinicReviewData.clinic.name : '' }}
        </router-link>
        {{ clinicReviewData.clinic ? '(' + clinicReviewData.clinic.city.name + ') - ' + clinicReviewData.clinic.type.name : '' }}
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="6">
        <b-form-group
          label="Город:"
          label-for="city_id"
        >
          <ValidationProvider
            #default="{ errors }"
            name="city_id"
            vid="city_id"
          >
            <v-select
              v-model="clinicReviewData.city_id"
              label="name"
              :reduce="option => option.id"
              :options="citiesList"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Клиника:"
          label-for="clinic_id"
        >
          <ValidationProvider
            #default="{ errors }"
            name="clinic_id"
            vid="clinic_id"
          >
            <v-select
              id="doctor_id"
              v-model="clinicReviewData.clinic_id"
              label="name"
              :reduce="option => option.id"
              :options="clinic_options"
              @search="onSearch"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
    </b-row>

    <hr>

    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Статус:"
          label-for="status"
        >
          <ValidationProvider
            #default="{ errors }"
            name="status"
            vid="status"
          >
            <b-form-select
              id="status"
              v-model="clinicReviewData.status"
              value-field="id"
              text-field="value"
              :options="[{id: null, value: 'Не выбрано'}, ...ClinicsReviewsConstants.REVIEW_STATUS_LIST]"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Текст отзыва:"
          label-for="description"
        >
          <ValidationProvider
            #default="{ errors }"
            name="description"
            vid="description"
          >
            <b-form-textarea
              id="description"
              v-model="clinicReviewData.description"
              placeholder="Введите текст отзыва"
              rows="5"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Оценка персонала:"
          label-for="rating_personal"
        >
          <ValidationProvider
            #default="{ errors }"
            name="rating_personal"
            vid="rating_personal"
          >
            <b-form-input
              id="rating_personal"
              v-model="clinicReviewData.rating_personal"
              autocomplete="off"
              placeholder="Оценка персонала"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Оценка оснащенности:"
          label-for="rating_equipment"
        >
          <ValidationProvider
            #default="{ errors }"
            name="rating_equipment"
            vid="rating_equipment"
          >
            <b-form-input
              id="rating_confidence"
              v-model="clinicReviewData.rating_equipment"
              autocomplete="off"
              placeholder="Рейтинг оснащенности"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <b-row v-if="clinicReviewData.id">
      <b-col cols="6">
        <b-form-group
          label="IP адрес:"
          label-for="ip_address"
        >
          <ValidationProvider
            #default="{ errors }"
            name="ip_address"
            vid="ip_address"
          >
            <b-form-input
              id="ip_address"
              v-model="clinicReviewData.ip_address"
              autocomplete="off"
              disabled=""
              placeholder="Ip адрес"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Юзер Агнет:"
          label-for="user_agent"
        >
          <ValidationProvider
            #default="{ errors }"
            name="user_agent"
            vid="user_agent"
          >
            <b-form-input
              id="user_agent"
              v-model="clinicReviewData.user_agent"
              autocomplete="off"
              disabled=""
              placeholder="Юзер Агнет"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="d-flex flex-row-reverse">
      <div>
        <b-button
          variant="primary"
          class="float-right"
          @click="formSubmit"
        >
          {{ clinicReviewData.id ? 'Обновить и закрыть' : 'Создать и закрыть' }}
        </b-button>
      </div>
      <div>
        <b-button
          variant="flat-danger"
          class="mr-1"
          @click="$router.back()"
        >
          Отменить
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BButton,
  BFormSelect,
  BFormTextarea,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import ClinicsReviewsConstants from '@/consts/clinicsReviews'
import _ from 'lodash'
import axiosIns from '@/libs/axios'

export default {
  name: 'ClinicsReviewsForm',
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    vSelect,
  },
  props: {
    clinicReviewData: {
      type: Object,
      require: true,
      default: () => {},
    },
    clinicOptions: {
      type: Array,
      require: true,
      default: () => [],
    },
    citiesList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      ClinicsReviewsConstants,
    }
  },
  computed: {
    clinic_options() {
      return this.clinicOptions.map(({ name, id }) => ({ name, id }))
    },
  },
  watch: {},
  methods: {
    // eslint-disable-next-line consistent-return
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        return this.search(loading, search, this.clinicReviewData, this)
      }
    },
    search: _.debounce(async (loading, search, clinicReviewData, vm) => {
      const res = await axiosIns({
        method: 'GET',
        url: `/clinics/search?search=${search}&city_id=${clinicReviewData.city_id}`,
      })

      let list = res.data.data.map(({ name, id }) => ({ name, id }))
      list = _.merge(list, _.pickBy(vm.selectedOptions, _.identity))
      const uniqueRes = _.uniqBy(list, e => e.id)
      vm.$emit('update:clinicOptions', uniqueRes)
      loading(false)
    }, 350),
    formSubmit() {
      this.$emit('formSubmit', true)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
