var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[(_vm.clinicReviewData.id && _vm.clinicReviewData.clinic)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('router-link',{attrs:{"to":{name: 'clinics-update', params: {id: _vm.clinicReviewData.clinic.id }}}},[_vm._v(" "+_vm._s(_vm.clinicReviewData.clinic ? _vm.clinicReviewData.clinic.name : '')+" ")]),_vm._v(" "+_vm._s(_vm.clinicReviewData.clinic ? '(' + _vm.clinicReviewData.clinic.city.name + ') - ' + _vm.clinicReviewData.clinic.type.name : '')+" ")],1)],1):_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Город:","label-for":"city_id"}},[_c('ValidationProvider',{attrs:{"name":"city_id","vid":"city_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","reduce":function (option) { return option.id; },"options":_vm.citiesList},model:{value:(_vm.clinicReviewData.city_id),callback:function ($$v) {_vm.$set(_vm.clinicReviewData, "city_id", $$v)},expression:"clinicReviewData.city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Клиника:","label-for":"clinic_id"}},[_c('ValidationProvider',{attrs:{"name":"clinic_id","vid":"clinic_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"doctor_id","label":"name","reduce":function (option) { return option.id; },"options":_vm.clinic_options},on:{"search":_vm.onSearch},model:{value:(_vm.clinicReviewData.clinic_id),callback:function ($$v) {_vm.$set(_vm.clinicReviewData, "clinic_id", $$v)},expression:"clinicReviewData.clinic_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Статус:","label-for":"status"}},[_c('ValidationProvider',{attrs:{"name":"status","vid":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"status","value-field":"id","text-field":"value","options":[{id: null, value: 'Не выбрано'} ].concat( _vm.ClinicsReviewsConstants.REVIEW_STATUS_LIST)},model:{value:(_vm.clinicReviewData.status),callback:function ($$v) {_vm.$set(_vm.clinicReviewData, "status", $$v)},expression:"clinicReviewData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Текст отзыва:","label-for":"description"}},[_c('ValidationProvider',{attrs:{"name":"description","vid":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","placeholder":"Введите текст отзыва","rows":"5"},model:{value:(_vm.clinicReviewData.description),callback:function ($$v) {_vm.$set(_vm.clinicReviewData, "description", $$v)},expression:"clinicReviewData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Оценка персонала:","label-for":"rating_personal"}},[_c('ValidationProvider',{attrs:{"name":"rating_personal","vid":"rating_personal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rating_personal","autocomplete":"off","placeholder":"Оценка персонала"},model:{value:(_vm.clinicReviewData.rating_personal),callback:function ($$v) {_vm.$set(_vm.clinicReviewData, "rating_personal", $$v)},expression:"clinicReviewData.rating_personal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Оценка оснащенности:","label-for":"rating_equipment"}},[_c('ValidationProvider',{attrs:{"name":"rating_equipment","vid":"rating_equipment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rating_confidence","autocomplete":"off","placeholder":"Рейтинг оснащенности"},model:{value:(_vm.clinicReviewData.rating_equipment),callback:function ($$v) {_vm.$set(_vm.clinicReviewData, "rating_equipment", $$v)},expression:"clinicReviewData.rating_equipment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),(_vm.clinicReviewData.id)?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"IP адрес:","label-for":"ip_address"}},[_c('ValidationProvider',{attrs:{"name":"ip_address","vid":"ip_address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ip_address","autocomplete":"off","disabled":"","placeholder":"Ip адрес"},model:{value:(_vm.clinicReviewData.ip_address),callback:function ($$v) {_vm.$set(_vm.clinicReviewData, "ip_address", $$v)},expression:"clinicReviewData.ip_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1524369355)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Юзер Агнет:","label-for":"user_agent"}},[_c('ValidationProvider',{attrs:{"name":"user_agent","vid":"user_agent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user_agent","autocomplete":"off","disabled":"","placeholder":"Юзер Агнет"},model:{value:(_vm.clinicReviewData.user_agent),callback:function ($$v) {_vm.$set(_vm.clinicReviewData, "user_agent", $$v)},expression:"clinicReviewData.user_agent"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1844193767)})],1)],1)],1):_vm._e(),_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('div',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.formSubmit}},[_vm._v(" "+_vm._s(_vm.clinicReviewData.id ? 'Обновить и закрыть' : 'Создать и закрыть')+" ")])],1),_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(" Отменить ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }